import { getCookie, setCookie } from 'cookies-next';
import { useState } from 'react';
import { NavigationViewType } from '../enums';

const SHOW_SWITCH_VIEW_TYPE_MODAL = 'showSwitchBrandLocationModal';

export type UseNavigationSwitchViewTypeReturns = {
    currentViewType: NavigationViewType;
    isOpenSwitchViewTypeModal: boolean;
    onChangeCurrentViewType: (viewType: NavigationViewType) => void;
    onChangeDontShowAgain: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClickNavigationSwitchViewButton: () => void;
    onCloseModal: () => void;
};

export function useNavigationSwitchViewType(): UseNavigationSwitchViewTypeReturns {
    const isCookieStored = getCookie(SHOW_SWITCH_VIEW_TYPE_MODAL);
    const [currentViewType, setCurrentViewType] = useState<NavigationViewType>(NavigationViewType.brand);
    const [isOpenSwitchViewTypeModal, setIsOpenSwitchViewTypeModal] = useState(false);
    const [isShowSwitchViewModal, setIsShowSwitchViewModal] = useState(isCookieStored === 'true');

    const onClickNavigationSwitchViewButton = () => {
        if (isShowSwitchViewModal) {
            const nextNewType =
                currentViewType === NavigationViewType.brand ? NavigationViewType.location : NavigationViewType.brand;
                setCurrentViewType(nextNewType);
        } else {
            setIsOpenSwitchViewTypeModal(true);
        }
    };

    const onCloseModal = () => {
        setIsOpenSwitchViewTypeModal(false)
    };

    const onChangeCurrentViewType = (viewType: NavigationViewType) => {
        setCurrentViewType(viewType);
    };

    const onChangeDontShowAgain = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsShowSwitchViewModal(event.target.checked);
        setCookie(SHOW_SWITCH_VIEW_TYPE_MODAL, event.target.checked);
    };

    return {
        currentViewType,
        isOpenSwitchViewTypeModal,
        onChangeCurrentViewType,
        onChangeDontShowAgain,
        onClickNavigationSwitchViewButton,
        onCloseModal,
    };
}
