import { Box, Toolbar } from '@deltasierra/react/components/core';
import { ReactNode } from 'react';
import { UseNavigationSwitchViewTypeReturns } from '../hooks';
import { LeftNavigationLeftDrawer, LeftNavigationLeftDrawerProps } from './LeftNavigationLeftDrawer';
import { LeftNavigationTopBar, LeftNavigationTopBarProps } from './LeftNavigationTopBar';

export type LeftNavigationProps = LeftNavigationLeftDrawerProps &
    LeftNavigationTopBarProps &
    UseNavigationSwitchViewTypeReturns & {
        children: ReactNode;
        isManager: boolean;
    };

export const LeftNavigation = ({
    children,
    contextPicker,
    currentViewType,
    helpUrl,
    homeHref,
    isLoadingNotifications,
    isManager,
    isOpenDrawer,
    isOpenSwitchViewTypeModal,
    logoImageSrc,
    navigation,
    notifications,
    notificationUrl,
    onChangeCurrentViewType,
    onChangeDontShowAgain,
    onClickDismissAllNotifications,
    onClickDismissNotification,
    onClickHamburger,
    onClickNavigationSwitchViewButton,
    onCloseModal,
    profile,
    t,
    user,
}: LeftNavigationProps): JSX.Element => (
    <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
        <LeftNavigationTopBar
            contextPicker={contextPicker}
            currentViewType={currentViewType}
            helpUrl={helpUrl}
            homeHref={homeHref}
            isLoadingNotifications={isLoadingNotifications}
            isManager={isManager}
            isOpenDrawer={isOpenDrawer}
            isOpenSwitchViewTypeModal={isOpenSwitchViewTypeModal}
            logoImageSrc={logoImageSrc}
            notificationUrl={notificationUrl}
            notifications={notifications}
            profile={profile}
            t={t}
            user={user}
            onChangeCurrentViewType={onChangeCurrentViewType}
            onChangeDontShowAgain={onChangeDontShowAgain}
            onClickDismissAllNotifications={onClickDismissAllNotifications}
            onClickDismissNotification={onClickDismissNotification}
            onClickHamburger={onClickHamburger}
            onClickNavigationSwitchViewButton={onClickNavigationSwitchViewButton}
            onCloseModal={onCloseModal}
        />

        <LeftNavigationLeftDrawer isOpenDrawer={isOpenDrawer} navigation={navigation} />
        <Box
            sx={{
                backgroundColor: 'common.white',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                height: '100%',
                overflowY: 'auto',
                width: '100%',
            }}
        >
            <Toolbar />
            <Box
                sx={{
                    backgroundColor: 'grey.100',
                    borderTopLeftRadius: '8px',
                    flex: 1,
                }}
            >
                {children}
            </Box>
        </Box>
    </Box>
);

LeftNavigation.displayName = 'LeftNavigation';
