import { GetGlobalDataQuery } from '@deltasierra/frontend/graphql';
import { AppWebRouterType } from '@deltasierra/frontend/routing/app-web';
import { AlertContextType } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { useNavigationSwitchViewType } from '../../hooks';
import { LeftNavigationProps } from '../LeftNavigation';
import { useLeftNavigationAccount } from './useLeftNavigationAccount';
import { useLeftNavigationDrawer } from './useLeftNavigationDrawer';
import { useLeftNavigationLeft } from './useLeftNavigationLeft';
import { useLeftNavigationNotifications } from './useLeftNavigationNotifications';

export type UseLeftNavigationProps = AlertContextType & {
    AppWebRouter: AppWebRouterType;
    clientId: string | null;
    isAdmin: boolean;
    isManager: boolean;
    locationId: string | null;
    me: GetGlobalDataQuery['me'];
    t: Translations<'Navigation'>;
};

export function useLeftNavigation({
    AppWebRouter,
    clientId,
    isAdmin,
    isManager,
    locationId,
    me,
    showAlert,
    t,
}: UseLeftNavigationProps): Omit<LeftNavigationProps, 'children' | 'logoImageSrc' | 't'> {
    const {
        currentViewType,
        isOpenSwitchViewTypeModal,
        onChangeCurrentViewType,
        onChangeDontShowAgain,
        onClickNavigationSwitchViewButton,
        onCloseModal,
    } = useNavigationSwitchViewType();
    const { isOpenDrawer, onClickHamburger } = useLeftNavigationDrawer(me.lavPreferenceOpen);

    const { navigation } = useLeftNavigationLeft(AppWebRouter, t, clientId, locationId, currentViewType);
    const { isLoadingNotifications, notifications, onClickDismissAllNotifications, onClickDismissNotification } =
        useLeftNavigationNotifications({
            showAlert,
            t,
        });

    const { profile, user } = useLeftNavigationAccount(AppWebRouter, me, isAdmin, t);

    return {
        currentViewType,
        helpUrl: AppWebRouter.get('/trythis'),
        homeHref: locationId ? `/dashboard/?l=${locationId}` : AppWebRouter.get('/'),
        isLoadingNotifications,
        isManager,
        isOpenDrawer,
        isOpenSwitchViewTypeModal,
        navigation,
        notificationUrl: AppWebRouter.get('/notifications'),
        notifications,
        onChangeCurrentViewType,
        onChangeDontShowAgain,
        onClickDismissAllNotifications,
        onClickDismissNotification,
        onClickHamburger,
        onClickNavigationSwitchViewButton,
        onCloseModal,
        profile,
        user,
    };
}
